.pool-page-section{
  margin-top: 25px;

  &_title{
    color: #ffffff;
    margin-bottom: 45px;
    display: flex;
    align-items: center;
    justify-content: center;

    .wax-title{
      display: flex;
      align-items: center;
      font-family: Lexend-ExtraLight, sans-serif;

      p{
           font-size: 40px;

        @media screen and (max-width: 559px){
          font-size: 25px;
        }
      }

      img{
        width: 40px;
        height: 40px;
        margin-right: 12px;

        @media screen and (max-width: 420px){
          width: 20px;
          height: 20px;
        }
      }

    }

    .arrow{
      width: 24px;
      height: 24px;
      margin: 0 26px;
      img{
        width: 100%;
        height: 100%;
      }
    }

    .name-collection{
      display: flex;
      align-items: center;
      font-size: 40px;
      font-family: Lexend-ExtraLight, sans-serif;

      @media screen and (max-width: 559px){
        font-size: 25px;
      }

      img{
        width: 50px;
        margin-right: 12px;

        @media screen and (max-width: 420px){
          width: 20px;
        }
      }
    }

  }

  .action-buttons{
    margin-bottom: 9px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 787px){
      flex-direction: column;
    }

    .view-all{
      font-size: 14px;
      color: #ffffff;
      width: 272px;
      height: 33px;
      border: 1px solid #E65634;
      background: transparent;
      margin-right: 22px;
      cursor: pointer;
      text-align: left;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      @media screen and (max-width: 1090px){
        width: 344px;
        height: 42px;
      }

      @media screen and (max-width: 787px){
        margin-bottom: 60px;
        margin-right: 0;
      }

      &-fst{
        display: flex;
        align-items: center;
        margin-right: 40px;
        font-size: 14px;

        @media screen and (max-width: 1090px){
          font-size: 18px;
        }


        img{
          width: 20px;
          height: 16px;
          margin: 0 12px 0 7px;
        }
      }

      &-snd{
      display: flex;
        align-items: center;
        font-family: Lexend-Regular, sans-serif;
        font-size: 12px;

        @media screen and (max-width: 1090px){
          font-size: 16px;
        }

        img{
          width: 20px;
          margin-right: 12px;
        }
      }

      &:hover{
        background: #ffffff;
        color: #000000;
      }
    }

    .direct-swap{
      font-size: 14px;
      color: #ffffff;
      width: 183px;
      height: 33px;
      border: 1px solid #E65634;
      background: transparent;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      @media screen and (max-width: 1090px){
        font-size: 18px;
        width: 232px;
        height: 42px;
      }

      img{
        width: 20px;
        margin: 0 12px 0 7px;
      }

      &:hover{
        background: #ffffff;
        color: #000000;
      }
    }

    .direct-trade-swap{
      font-size: 14px;
      color: #ffffff;
      width: 95px;
      height: 33px;
      border: 1px solid #E65634;
      background: transparent;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      @media screen and (max-width: 1090px){
        font-size: 18px;
        width: 232px;
        height: 42px;
      }

      img{
        width: 20px;
        margin: 0 12px 0 7px;
      }

      &:hover{
        background: #ffffff;
        color: #000000;
      }
    }
  }

  .assets-block-buy{
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    margin-bottom: 17px;


    @media screen and (max-width: 1090px){
      flex-direction: column;
      margin-bottom: 12px;
    }


    .assets{
      width: 521px;
      height: 360px;
      background: #111122;
      border-radius: 5px;
      margin-right: 10px;

      @media screen and (max-width: 1090px){
        width: 754px;
        margin-right: 0;
        margin-bottom: 12px;
      }

      @media screen and (max-width: 787px){
        width: 500px;
      }

      @media screen and (max-width: 520px){
        width: 350px;
      }

      &_title{
        padding-top: 11px;
        margin-bottom: 24px;

        h5{
          font-size: 18px;
        }
      }

      .deposit-field{
        width: 440px;
        height: 76px;
        background: #000000;
        margin: 0 auto;

        @media screen and (max-width: 1090px){
          width: 672px;
        }

        @media screen and (max-width: 787px){
          width: 450px;
        }

        @media screen and (max-width: 520px){
          width: 280px;
        }


        .wrapper{
          height: 75%;
          padding: 10px 14px 10px 10px;
          display: flex;
          justify-content: space-between;

          .deposit-field-tokens{

            input{
              background: transparent;
              border: 2px solid #2B282C;
              color: #ffffff;
              width: 200px;
              height: 25px;

              &:hover{
                border: 2px solid #FF6C4A;
              }

              &:focus{
                border: 2px solid #FF6C4A;
              }
            }

            .little-title{
              color: #D8D8D8;
              font-size: 12px;
              text-align: left;
              margin-bottom: 11px;
            }

            .amount-token{
              color: #FFFFFF;
              font-size: 22px;
            }
          }

          .deposit-withdraw-btn{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-end;

            .deposit-btn, .withdraw-btn, .save-btn{
              color: #FFFFFF;
              font-size: 12px;
              width: 93px;
              height: 24px;
              background: #2B282C;
              border-radius: 15px;
              border: none;

              &:hover{
                background: rgba(43, 40, 44, 0.87);
              }
            }
          }
        }
      }
    }

    .about{

      .pricing{
        width: 521px;
        height: 152px;
        background: #111122;
        border-radius: 5px;
        margin-bottom: 14px;
        position: relative;

        @media screen and (max-width: 1090px){
          width: 754px;
          margin-right: 0;
          margin-bottom: 12px;
        }
        @media screen and (max-width: 787px){
          width: 500px;
          margin: 0 auto 14px auto;
        }

        @media screen and (max-width: 520px){
          width: 350px;
        }

        .edit{
          position: absolute;
          top: 13px;
          right: 40px;


          button{
            width: 93px;
            height: 24px;
            background: #2B282C;
            border-radius: 15px;
            border: none;
            color: #FFFFFF;

            &:hover{
              background: rgba(43, 40, 44, 0.87);
            }
          }
        }

        &_title{
          padding-top: 11px;
          margin-bottom: 24px;

          h5{
            font-size: 18px;
          }
        }

        &-info{
          display: flex;
          justify-content: center;
          align-items: center;

          .current-price{
            width: 218px;
            height: 80px;
            background: #000000;
            margin-right: 7px;
            text-align: left;
            justify-content: center;

            @media screen and (max-width: 1090px){
              width: 334px;
            }

            .little-title{
              color: #D8D8D8;
              font-size: 12px;
              text-align: left;
              margin-bottom: 12px;
            }


            .wrapper{
              padding: 10px 14px 10px 10px;
              height: 75%;


              .floor-price{
                font-size: 18px;
                color: #FFFFFF;
                text-align: left;

                @media screen and (max-width: 520px){
                  font-size: 15px;
                }
              }
            }
          }

          .delta{
            width: 218px;
            height: 80px;
            background: #000000;
            margin-right: 7px;
            text-align: left;

            @media screen and (max-width: 1090px){
              width: 334px;
            }

            .wrapper{
              padding: 10px 14px 10px 10px;
              height: 75%;

              .little-title{
                color: #D8D8D8;
                font-size: 12px;
                text-align: left;
                margin-bottom: 12px;
              }

              .delta-number{
                font-size: 18px;
                color: #ffffff;
                text-align: left;

                @media screen and (max-width: 520px){
                  font-size: 15px;
                }
              }
            }
          }
        }
      }

      .about-owner{
        width:  calc(487px - 38px);
        height: 199px;
        background: #111122;
        border-radius: 5px;
        padding: 0 38px;

        @media screen and (max-width: 1090px){
          width:  calc(754px - 67px);
          padding: 0 19px 0 48px;
          height: 319px;
        }

        @media screen and (max-width: 787px){
          width: 435px;
        }

        @media screen and (max-width: 520px){
          width: 287px;
        }

        &_title{
          padding-top: 11px;
          margin-bottom: 24px;
        }

        &_info{
          text-align: left;
          p{
            font-family: Lexend-Medium, sans-serif;
            font-size: 12px;
            margin-bottom: 8px;

            @media screen and (max-width: 1090px){
              font-size: 16px;
            }

          }

          &-address{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 188px;
            height: 33px;
            background: #2B282C;
            border-radius: 15px;
            margin-bottom: 22px;

            @media screen and (max-width: 1090px){
              margin-bottom: 39px;
            }
            p{
              font-size: 14px;
              margin-bottom: 0;

              @media screen and (max-width: 1090px){
                font-size: 16px;
              }

            }
          }
        }

        &_pool-info{
          text-align: left;
          font-family: Lexend-Medium, sans-serif;
          font-size: 12px;

          @media screen and (max-width: 1090px){
            font-size: 18px;
          }

          @media screen and (max-width: 520px){
            font-size: 14px;
          }

          .mini-wax-logo{
            width: 10px;
            height: 10px;
          }

          span{
            color: #E65634;
          }
        }
      }
    }
  }

  .diagram-block{
    position: relative;
    width: 1064px;
    min-height: calc(487px - 20px);
    background: #111122;
    border-radius: 5px;
    margin: 0 auto 60px auto;
    padding-bottom: 20px;

    @media screen and (max-width: 1090px){
      width: 754px;
    }

    &_title{
      padding-top: 22px;
      margin-bottom: 28px;
      color: #ffffff;
      font-size: 18px;

      h5{
        font-size: 18px;
      }
    }

    .amount-slider{
      width: 227px;
      height: 99px;
      background: #000000;
      margin: 28px auto 26px auto;
      display: flex;
      align-items: center;
      justify-content: space-around;
      flex-direction: column;

      h4{
        color: #ffffff;
        font-size: 12px;
        margin-top: 5px;

        span{
          color: #E65634;
        }
      }

      p{
        color: #ffffff;
        font-size: 12px;
        margin-bottom: 5px;

        @media screen and (max-width: 520px){
          margin-top: 15px;
        }

        span{
          color: #E65634;
        }
      }
    }

    .diagram-block-content{
      max-width: 941px;
      margin: 0 auto;
    }

  }



  // Rodal

  .rodal-update-pool{

    .rodal-dialog{
      width: 210px!important;
      height: 520px!important;

      .rodal-title{
        color: #ffffff;
      }

      .rodal-form{
        margin-top: 40px;
        &_field{
          margin-bottom: 10px;
          width: 98%;
          input{
            border: 1px solid #E65634;
            background: transparent;
            color: #ffffff;
            padding: 10px 20px;
          }

          p{
            text-align: left;
            font-size: 12px;
            color: #ffffff;
            margin-bottom: 2px;
          }
        }
      }

      .update-btn{
        margin-top: 40px;


        button{
          margin: 0;
        }
      }
    }

    .rodal-dialog::after{
      display: none;
    }

  }

}
