.all-pools{
  position: relative;

  .all-pools.scroll-locked {
    overflow: hidden;
  }

  .scrollable-block {
    overflow-y: auto;
    height: 100%;
  }

  .mobile-filter{
    display: none;


    &-top{
      display: flex;
      justify-content: space-between;

      button{
        font-size: 18px;
      }

      margin: 30px 36px 55px 36px;
    }

    .side-bar{
      display: block;
      margin: 0 auto;
      width: 505px;

      @media screen and (max-width: 560px){
        width: 405px;
      }

      @media screen and (max-width: 460px){
        width: 305px;
      }


      .collections{
        width: 100%;

        select{
          width: 100%;
          height: 59px;
          font-size: 18px;
        }
        p{
          font-size: 24px;
        }
      }

      .schemas{
        width: 100%;
        margin-top: 20px;

        p{
          font-size: 24px;
        }

        .schemas-list{
          width: 100%;
          height: 188px;
          background: #11112280 0 0;
          overflow: auto;
          padding: 10px 5px 5px 13px;

          p{
            font-size: 18px;

            &:hover{
              color: #D8D8D8;
            }
          }
        }
      }

      .tool-type{
        width: 100%;
        margin-top: 20px;

        p{
          font-size: 24px;
        }

        &-block{
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          height: 59px;
          background: #000000 0 0 no-repeat padding-box;
          border-radius: 5px;
          margin-bottom: 5px;


          p{
            color: #FFFFFF;
            font-size: 18px;
            padding: 10px ;
            margin: 0;
          }

          input[type=checkbox]{
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            width: 27px;
            height: 27px;
            background: #2B282C;
            border: 1px solid #707070;
            cursor: pointer;
            margin-right: 10px;
          }
          input[type=checkbox]:checked::before {
            content: "\2713";
            margin: -2px 2px;
            font-size: 21px;
            color: #fff;
            //background-color: #2B282C;
            width: 27px;
            height: 27px;
            border-radius: 3px;
            display: block;
          }
        }
      }

    }
  }

  .mobile-filter.opened{
    display: block;
    background: #111122;
    position: absolute;
    width: 100%;
    height: 750px;
    top: 0;
    left: 0;
    z-index: 4;
    box-shadow: 2px 80px 150px rgba(0, 0, 0, 1);
  }


  &_title{
    margin: 30px 0 11px;


    @media screen and (max-width: 678px){
      //display: flex;
      //align-items: center;
      //justify-content: flex-end;
    }


    &-left{

      @media screen and (max-width: 960px){
        //margin-right: 20px;
      }
    }

    .filter-button{
      display: none;
    }
    
    @media screen and (max-width: 970px){
      .filter-button{
        display: flex;
        margin-left: 37px;

        button{
          margin: 0;
        }
      }
    }

    @media screen and (max-width: 478px){
      .filter-button{
        justify-content: center;
        margin: 0;
      }
    }

    .create-new {
      margin-top: 22px;
      margin-bottom: 37px;

      .orange-border-button {
        margin: 0;
      }

      @media screen and (max-width: 970px) {
        button {
          width: 224px!important;
          height: 64px!important;
        }
      }
    }



    h2{
      font-family: "Lexend-ExtraLight", sans-serif;
      font-size: 40px;
      color: #FFFFFF;
      font-weight: 200;
    }
  }


  &-content{
    display: flex;
    justify-content: center;
  }
  &_list{
    margin-left: 32px;
    width: 1130px;
    display: flex;
    flex-wrap: wrap;
    justify-content: start;

    @media screen and (max-width: 1440px){
      width: 855px;
    }

    @media screen and (max-width: 960px){
      margin: 0;
      justify-content: center;
    }

    .loader-data{
      margin: 0 auto;
    }
  }
}
