.App {
  text-align: center;
}

button{
  cursor: pointer;
  font-family: 'Lexend-Medium', sans-serif;
}

p, h1, h2, h3, h4, h5, h6{
  margin: 0;
  padding: 0;
}

a{
  text-decoration: none;
}

.css-187mznn-MuiSlider-root, .css-14pt78w-MuiSlider-rail, .css-exkjcx{
  color: #E65634!important;
}

.MuiSlider-root.MuiSlider-colorPrimary.MuiSlider-sizeMedium.css-exkjcx{
  color: #E65634!important;
}

