.swap-page{
  color: #ffffff;
  width: 991px;
  margin: 0 auto;

  @media screen and (max-width: 1015px){
    width: 440px;
  }

  @media screen and (max-width: 540px){
    width: 320px;
  }

  &_title{
    margin: 40px 0 90px 0;
    h2{
      font-size: 40px;

    }
  }

  .select-pool-type{
    margin-bottom: 10px;
  }

  .pool-switcher{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }


  .pool-block{
    width: 991px;
    height: 320px;
    background: #000000;
    border-radius: 5px;
    margin: 0 auto;

    @media screen and (max-width: 1015px){
      width: 440px;
    }

    @media screen and (max-width: 540px){
      width: 320px;
    }

    &_wrapper{
      margin: 0 31px 0 31px;
      padding: 20px 0;

      .item-type{
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .container{
        width: 100%;
        height: 155px;
        background: #2B282C;
        border-radius: 5px;
        margin-bottom: 17px;
        position: relative;
        margin-top: 20px;

        &-wrapper{
          margin: 0 27px 0 27px;
          padding: 15px 0 15px 0;


          .price{
            display: flex;
            align-items: center;
            justify-content: space-between;
            text-align: left;
            border-bottom: 2px solid #000000;
            padding-bottom: 20px;

            &_left{


              .little-title{
                color: #D8D8D8;
                font-size: 12px;
                text-align: left;
                margin-bottom: 11px;
              }

              .price-amount{
                p{
                  font-size: 22px;
                }
              }
            }

            &_right{
              .little-title{
                color: #D8D8D8;
                font-size: 12px;
                text-align: right;
                margin-bottom: 11px;
              }

              .price-amount{
                p{
                  font-size: 22px;
                }
              }
            }
          }

          .delta-balance{
            display: flex;
            align-items: center;
            justify-content: space-between;
            .delta{
              text-align: left;
              .delta-title{
                margin: 10px 0 5px 0;
              }

              .delta-amount{

              }
            }

            .balance{
              text-align: right;
              .balance-title{
                margin: 10px 0 5px 0;
              }

              .balance-amount{

              }
            }
          }
        }

        .pool-owner{
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 20px 0 0 0;

        }
      }
    }
  }

  .nfts-block{
    margin: 30px 0;
    text-align: left;

    h4{
      font-size: 20px;
    }

    &_list{
      margin: 20px 0;
      width: 100%;
      height: 400px;
      background-color: #000000;
      padding: 20px 0;
      overflow: hidden;

      @media screen and (max-width: 1015px){
        margin: 15px auto 0 auto;
      }


      .wrapper{
        height: 77%;
        display: flex;
        flex-wrap: wrap;
        padding: 40px;
        overflow-y: auto;
        overflow-x: hidden;

        @media screen and (max-width: 540px){
          padding: 40px 20px;
        }

        .loader{
          margin: 0 auto;
        }

        .load-more-swap{
          margin: 0 auto;
        }
      }


      &_item{
        width: 120px;
        height: 160px;
        border-radius: 10px;
        background: #111122;
        margin-right: 5px;
        margin-bottom: 5px;
        cursor: pointer;

        @media screen and (max-width: 540px){
          width: 130px;
        }

        &-image{
          width: 110px;
          height: 110px;
          border-radius: 10px;
          margin: 7px auto 5px auto;
          text-align: center;

          img{
            //width: 100%;
            height: 100%;
          }
        }

        &-info{
          text-align: center;
          p{
            font-size: 10px;
          }

          h6{
            font-size: 12px;
          }
        }

        &:hover{
          transform: scale(1.1);
        }

        &.active{
          border: 1px solid #E65634;
        }

        img{
          //width: 100%;
          height: 100%;
        }
      }
    }

  }

  .total-price-block{
    text-align: left;
    margin: 0 0 40px 0;

    h4{
      font-size: 20px;
      margin-bottom: 15px;

      span{
        color: #E65634;
      }
    }
  }

  .action-btn{
    display: flex;

    @media screen and (max-width: 540px){
      flex-direction: column;
    }

    .confirm{
      margin: 0 0 40px 0;
    }
  }


}
