.orange-border-button{
  margin-right: 15px;
  width: 115px;
  height: 33px;
  border: 1px solid #E65634;
  background-color: transparent;
  font-size: 0.875rem;
  padding: 3px 15px;
  text-align: center;
  color: #FFFFFF;
  font-weight: 500;
  position: relative;

  &:hover{
    color: #000000;
    background-color: #FFFFFF;
  }

  &.active{
    color: #000000;
    background-color: #FFFFFF;
  }
}

.orange-border-button.next-btn{
  border: 1px solid rgba(234, 216, 216, 0.62);
  color: rgba(234, 216, 216, 0.62);

  &:hover{
    cursor: default;
    background-color: transparent;
  }
}

.orange-border-button.hide-for-foure-step{
  display: none;
}
