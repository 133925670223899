.description{
  width: 1112px;
  height: 215px;
  font-size: 3.125rem;
  text-align: left;
  margin: 80px auto 93px auto;


  @media screen and (max-width: 1699px){
    width: 980px;
  }

  @media screen and (max-width: 1290px){
    width: 750px;
  }

  @media screen and (max-width: 1024px){
    margin: 80px auto 70px auto;
  }

  @media screen and (max-width: 950px){
    width: 625px;
  }

  @media screen and (max-width: 645px){
    width: 94%;
  }

  @media screen and (max-width: 525px){
    margin-bottom: 110px;
  }

  @media screen and (max-width: 425px){
    margin-bottom: 110px;
  }

  h1{
    width: 100%;
    color: #FFFFFF;
    font-size: 3.125rem;
    font-weight: 200;
    margin-bottom: 28px;
    font-family: 'Lexend-ExtraLight', sans-serif;

    @media screen and (max-width: 1699px){
      font-size: 3.125rem;
    }

    @media screen and (max-width: 950px){
      text-align: center;
    }

    @media screen and (max-width: 645px){
      font-size: 3rem;
    }

    @media screen and (max-width: 600px){
      font-size: 2.5rem;
      text-align: center;
    }

    @media screen and (max-width: 525px){
      font-size: 2.3rem;
      text-align: center;
    }

    @media screen and (max-width: 460px){
      font-size: 2rem;
    }

    @media screen and (max-width: 415px){
      font-size: 1.9rem;
    }

    @media screen and (max-width: 380px){
      font-size: 1.7rem;
    }
  }

  span{
    color: #E65634;
    font-weight: bold;
    font-family: 'Lexend-SemiBold', sans-serif;
  }


  .about{
    display: flex;


    @media screen and (max-width: 525px){
      flex-direction: column;
      align-items: center;
    }

    &_item{
      width: 245px;
      height: 49px;
      margin-right: 45px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      background: rgba(188, 33, 31, 0.2) 0 0 no-repeat padding-box;
      position: relative;

      @media screen and (max-width: 1699px){
        width: 220px;
      }

      @media screen and (max-width: 950px){
        &:last-child{
          margin-right: 0;
        }
      }

      @media screen and (max-width: 525px){
        margin: 0 0 20px 0;
      }

      img{
        position: absolute;
        top: -12px;
        left: -7px;
        width: 65px;
        height: 60px;

        @media screen and (max-width: 1699px){
          width: 60px;
          top: -14px;
          height: auto;
        }
      }

      p{
        font-size: 1.25rem;
        color: #FFFFFF;
        padding-left: 60px;
        font-weight: 500;

        @media screen and (max-width: 1699px){
          font-size: 1rem;
        }

        @media screen and (max-width: 645px){
          font-size: 0.9rem;
        }
      }
    }

    &_item:last-child{
      //margin: 0;

      @media screen and (max-width: 525px){

      }
    }
  }
}

.airdrop-button{
  margin: 80px 0;

  @media screen and (max-width: 1024px){
    margin: 45px 0;
  }
}
.airdrop-button button{
  font-size: 40px;
}

.select-options{
  display: flex;
  align-items: center;
  width: 1112px;
  margin: 0 auto 100px auto;

  @media screen and (max-width: 1699px){
    width: 980px;
  }

  @media screen and (max-width: 1290px){
    width: 850px;
    height: 180px;
  }

  @media screen and (max-width: 950px){
    display: block;
    height: 820px;
    width: 475px;
  }

  @media screen and (max-width: 625px){
    height: 615px;
  }

  @media screen and (max-width: 525px){
    width: 365px;
  }

  @media screen and (max-width: 415px){
    width: 313px;
    height: 475px;
  }

  &_item{
    background-image: url("../images/Path 4.svg");
    background-size: 350px auto;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 183px;
    margin-right: 17px;

    @media screen and (max-width: 1699px){
      background-size: 315px auto;
    }

    @media screen and (max-width: 1290px){
      background-size: 285px auto;
    }

    @media screen and (max-width: 950px){
      background-size: 450px auto;
      height: 255px;
    }

    @media screen and (max-width: 625px){
      background-size: 333px auto;
      height: 190px;
    }

    @media screen and (max-width: 415px){
      background-size: 275px auto;
      height: 155px;
    }

    .content{
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: center;

      @media screen and (max-width: 950px){
        height: 115px;
      }

      @media screen and (max-width: 625px){
        height: 70px;
      }

      p{
        color: #FFFFFF;
        width: 25px;
        height: 45px;
        font-size: 30px;
      }

      .first-icon{
        width: 77px;
        height: 77px;
        display: flex;
        justify-content: center;

        @media screen and (max-width: 950px){
          width: 115px;
          height: 110px;
        }

        @media screen and (max-width: 625px){
          width: 79px;
          height: 74px;
        }
      }

      .middle-icon{
        margin: 0 10px 0 7px;
      }

      .last-icon{
        display: flex;
        justify-content: center;
        width: 70px;
        height: 77px;

        @media screen and (max-width: 950px){
          width: 115px;
          height: 115px;
        }

        @media screen and (max-width: 625px){
          width: 79px;
          height: 74px;
        }
      }
    }

    h4{
      font-size: 1.25rem;
      color: #FFFFFF;
      padding-top: 30px;
      text-align: center;
      font-family: 'Lexend-Medium', sans-serif;

      @media screen and (max-width: 1699px){
        font-size: 1rem;
      }

      @media screen and (max-width: 1290px){
        font-size: 0.85rem;
      }

      @media screen and (max-width: 950px){
        font-size: 1.5rem;
      }

      @media screen and (max-width: 625px){
        font-size: 1.2rem;
      }

      @media screen and (max-width: 415px){
        font-size: 0.9rem;
        padding-top: 15px;
      }
    }

    &:nth-child(2){
      margin-right: 5px;
    }
  }


  &_item_both{
    background-image: url("../images/Path 6.svg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 109%;
    height: 237px;

    @media screen and (max-width: 1699px){
      width: 100%;
      height: 220px;
      background-size: contain;
    }

    @media screen and (max-width: 950px){
      height: 310px;
    }


    @media screen and (max-width: 625px){
      height: 260px;
    }

    @media screen and (max-width: 415px){
      height: 200px;
    }


    h4{
      font-size: 1.25rem;
      color: #FFFFFF;
      padding-top: 53px;
      text-align: center;
      font-family: 'Lexend-Medium', sans-serif;

      @media screen and (max-width: 1699px){
        font-size: 1rem;
      }

      @media screen and (max-width: 1290px){
        font-size: 0.85rem;
        padding-top: 50px;
      }

      @media screen and (max-width: 950px){
        font-size: 1.5rem;
      }

      @media screen and (max-width: 625px){
        font-size: 1.2rem;
      }

      @media screen and (max-width: 415px){
        font-size: 0.9rem;
        padding-top: 25px;
      }
    }

    .content{
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 30px;
      margin-right: 30px;

      @media screen and (max-width: 950px){
        height: 115px;
      }

      @media screen and (max-width: 415px){
        margin-top: 15px;
      }

      .colab{
        display: flex;
        align-items: center;

        .first-icon{
          width: 77px;
          height: 77px;
          display: flex;
          justify-content: center;

          @media screen and (max-width: 950px){
            width: 87px;
            height: 110px;
          }

          @media screen and (max-width: 625px){
            width: 90px;
            height: 95px;
          }

          img{
            margin-left: 70px;
          }
        }


        .second-icon{
          width: 77px;
          height: 77px;
          display: flex;
          justify-content: center;

          @media screen and (max-width: 950px){
            width: 115px;
            height: 115px;
          }

          @media screen and (max-width: 625px){
            width: 100px;
            height: 96px;
          }
        }
      }

      .middle-icon{
        margin: 0 7px;
      }

      .procent{
        font-size: 60px;
        color: #FFFFFF;
      }
    }
  }
}

.benefits{
  height: 440px;
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-bottom: 72px;

  @media screen and (max-width: 1599px){
    height: 365px;
  }

  @media screen and (max-width: 1399px){
    height: 320px;
  }

  @media screen and (max-width: 1290px){
    height: 290px;
  }

  @media screen and (max-width: 1050px){
    height: 240px;
  }

  @media screen and (max-width: 950px){
    display: block;
    height: 792px;
    width: 70%;
    margin: 0 auto;
  }


  @media screen and (max-width: 735px){
    height: 645px;
  }

  @media screen and (max-width: 525px){
    height: 455px;
    width: 100%;
  }

  @media screen and (max-width: 415px){
    height: 380px;
  }

  &-center-icon{
    width: 498px;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @media screen and (max-width: 1799px){
      top: 48%;
      width: 415px;
    }

    @media screen and (max-width: 1599px){
      top: 52%;
      width: 365px;
    }

    @media screen and (max-width: 1399px){
      top: 55%;
      width: 300px;
    }

    @media screen and (max-width: 1290px){
      width: 220px;
      top: 42%;
    }

    @media screen and (max-width: 1050px){
      top: 48%;
    }

    @media screen and (max-width: 950px){
      display: none;
    }

    img{
      width: 100%;
      height: 100%;
    }
  }


  &-left{
    background-image: url("../images/Rectangle 15.svg");
    background-position: right top;
    background-size: contain;
    width: 49.8%;
    background-repeat: no-repeat;



    @media screen and (max-width: 950px){
      background-image: url("../images/Rectangle_15-mobile.png");
      width: 615px;
      height: 365px;
      background-position: center;
      margin: 0 auto 22px auto;
      position: relative;

      &:before{
        content: "";
        background-image: url('../images/comp-b2.png');
        width: 300px;
        height: 200px;
        display: inherit;
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        top: 100px;
        left: -100px;

        @media screen and (max-width: 735px){
          width: 215px;
          height: 140px;
          top: 90px;
          left: -70px;
        }

        @media screen and (max-width: 625px){
          width: 185px;
          height: 120px;
          top: 80px;
          left: -60px;
        }

        @media screen and (max-width: 525px){
          width: 150px;
          height: 100px;
          top: 60px;
          left: -44px;
        }

        @media screen and (max-width: 415px){
          width: 115px;
          height: 85px;
          top: 48px;
          left: -34px;
        }
      }
    }

    @media screen and (max-width: 735px){
      width: 465px;
      height: 270px;
    }

    @media screen and (max-width: 625px){
      width: 425px;
      height: 252px;
    }

    @media screen and (max-width: 525px){
      width: 295px;
      height: 175px;
    }

    @media screen and (max-width: 415px){
      width: 260px;
      height: 145px;
    }

    h3{
      color: #E65634;
      text-align: end;
      font-size: 40px;
      padding-top: 19px;
      padding-right: 75px;
      margin-bottom: 38px;
      font-family: 'Lexend-ExtraLight', sans-serif;

      @media screen and (max-width: 1799px){
        font-size: 35px;
        margin-bottom: 40px;
      }

      @media screen and (max-width: 1699px){
        font-size: 32px;
        margin-bottom: 50px;
      }

      @media screen and (max-width: 1599px){
        margin-bottom: 40px;
      }
      @media screen and (max-width: 1290px){
        font-size: 28px;
        margin-bottom: 14px;
        padding-top: 10px;
      }

      @media screen and (max-width: 950px){
        text-align: left;
        padding-left: 120px;
        margin-bottom: 35px;
        padding-top: 21px;
        font-size: 33px;
      }

      @media screen and (max-width: 735px){
        font-size: 30px;
        margin-bottom: 25px;
      }
      @media screen and (max-width: 625px){
        font-size: 25px;
        margin-bottom: 15px;
        padding-left: 23px;
        padding-right: 0;
      }

      @media screen and (max-width: 525px){
        font-size: 19px;
        padding-right: 0;
        padding-left: 23px;
      }

      @media screen and (max-width: 415px){
        padding-top: 10px;
        font-size: 16px;
      }
    }

    p{
      font-size: 22px;
      color: #FFFFFF;
      text-align: right;
      padding-right: 258px;
      padding-bottom: 60px;
      font-family: 'Lexend-Regular', sans-serif;

      @media screen and (max-width: 1799px){
        padding-bottom: 48px;
        padding-right: 225px;
      }

      @media screen and (max-width: 1699px){
        padding-bottom: 50px;
        padding-right: 225px;
        font-size: 20px;
      }

      @media screen and (max-width: 1599px){
        padding-bottom: 40px;
        padding-right: 195px;
        font-size: 20px;
      }

      @media screen and (max-width: 1399px){
        padding-bottom: 30px;
        padding-right: 175px;
        font-size: 18px;
      }

      @media screen and (max-width: 1290px){
        font-size: 14px;
        padding-right: 123px;
        padding-bottom: 22px;
      }

      @media screen and (max-width: 950px){
        font-size: 18px;
        text-align: left;
        padding-left: 210px;
        padding-right: 0;
        padding-bottom: 50px;
      }

      @media screen and (max-width: 735px){
        padding-left: 145px;
        padding-bottom: 25px;
      }

      @media screen and (max-width: 625px){
        padding-left: 150px;
        padding-bottom: 15px;
      }

      @media screen and (max-width: 525px){
        padding-left: 120px;
        font-size: 10px;
        padding-bottom: 18px;
      }

      @media screen and (max-width: 415px){
        padding-left: 85px;
        padding-bottom: 11px;
      }
    }

  }

  &-right{
    background-image: url("../images/Rectangle 16.svg");
    background-position: left top;
    background-size: contain;
    width: 48.8%;
    background-repeat: no-repeat;

    @media screen and (max-width: 950px){
      background-image: url("../images/Rectangle_16-mobile.png");
      width: 615px;
      height: 305px;
      background-position: center;
      margin: 0 auto;
      position: relative;

      &:before{
        content: "";
        background-image: url('../images/comp-b1.png');
        width: 300px;
        height: 200px;
        display: inherit;
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        top: 85px;
        left: -100px;

        @media screen and (max-width: 735px){
          width: 215px;
          height: 140px;
          top: 90px;
          left: -70px;
        }

        @media screen and (max-width: 625px){
          width: 185px;
          height: 120px;
          top: 80px;
          left: -60px;
        }

        @media screen and (max-width: 525px){
          width: 150px;
          height: 100px;
          top: 60px;
          left: -44px;
        }

        @media screen and (max-width: 415px){
          width: 115px;
          height: 85px;
          top: 48px;
          left: -34px;
        }

      }
    }

    @media screen and (max-width: 735px){
      width: 465px;
      height: 240px;
    }

    @media screen and (max-width: 625px){
      width: 425px;
      height: 222px;
    }

    @media screen and (max-width: 525px){
      width: 295px;
      height: 150px;
    }

    @media screen and (max-width: 415px){
      width: 260px;
      height: 130px;
    }

    h3{
      color: #E65634;
      text-align: start;
      font-size: 40px;
      padding-top: 19px;
      padding-left: 75px;
      margin-bottom: 27px;
      font-family: 'Lexend-ExtraLight', sans-serif;

      @media screen and (max-width: 1799px){
        font-size: 35px;
        margin-bottom: 35px;
      }

      @media screen and (max-width: 1699px){
        margin-bottom: 40px;
      }

      @media screen and (max-width: 1599px){
        font-size: 32px;
        margin-bottom: 30px;
      }
      @media screen and (max-width: 1290px){
        font-size: 28px;
        margin-bottom: 14px;
        padding-top: 10px;
      }

      @media screen and (max-width: 950px){
        font-size: 33px;
        padding-top: 21px;
        margin-bottom: 21px;
      }

      @media screen and (max-width: 735px){
        font-size: 30px;
        margin-bottom: 25px;
      }

      @media screen and (max-width: 625px){
        padding-left: 23px;
        font-size: 25px;
      }

      @media screen and (max-width: 525px){
        font-size: 19px;
        padding-right: 0;
        padding-left: 23px;
        margin-bottom: 15px;
      }

      @media screen and (max-width: 415px){
        padding-left: 25px;
        padding-top: 10px;
        font-size: 16px;
      }
    }

    p{
      font-size: 22px;
      color: #FFFFFF;
      text-align: left;
      padding-left: 238px;
      padding-bottom: 60px;
      font-family: 'Lexend-Regular', sans-serif;

      @media screen and (max-width: 1799px){
        padding-bottom: 45px;
        padding-left: 210px;
      }

      @media screen and (max-width: 1699px){
        padding-bottom: 50px;
        padding-left: 205px;
        font-size: 20px;
      }

      @media screen and (max-width: 1599px){
        padding-bottom: 40px;
        padding-left: 180px;
        font-size: 20px;
      }

      @media screen and (max-width: 1399px){
        padding-bottom: 30px;
        padding-left: 160px;
        font-size: 18px;
      }
      @media screen and (max-width: 1290px){
        font-size: 14px;
        padding-bottom: 25px;
        padding-left: 115px;
      }

      @media screen and (max-width: 950px){
        font-size: 18px;
        text-align: left;
        padding-left: 210px;
        padding-right: 0;
        padding-bottom: 50px;
      }

      @media screen and (max-width: 735px){
        padding-left: 150px;
        padding-bottom: 25px;
      }

      @media screen and (max-width: 625px){
        padding-left: 150px;
        padding-bottom: 15px;
      }

      @media screen and (max-width: 525px){
        padding-left: 120px;
        font-size: 10px;
        padding-bottom: 18px;
      }

      @media screen and (max-width: 415px){
        padding-left: 85px;
        padding-bottom: 11px;
      }
    }
  }
}

.top-sellers{
  width: 1365px;
  margin: 0 auto 50px auto;

  &_wrapper{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
}


.view-all-collections {
  position: relative;
  margin-bottom: 150px;
  display: inline-block;
  width: 30%;
  cursor: pointer;
}

.view-all-collections .a {
  transition: fill 0.3s ease;
}

.view-all-collections span {
  position: absolute;
  top: 47%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #FFFFFF;
  background-color: transparent;
  transition: background-color 0.3s ease;
}

.view-all-collections:hover .a {
  fill: #fff;
}

.view-all-collections:hover span {
  color: #000;
}

.view-all-collections:hover span ~ .a {
  fill: #000;
}

.tokenomics-block{
  width: 1140px;
  margin: 0 auto 75px auto;

  @media screen and (max-width: 1399px){
    width: 673px;
  }

  @media screen and (max-width: 768px){
    width: 520px;
  }

  @media screen and (max-width: 620px){
    width: 400px;
  }

  @media screen and (max-width: 524px){
    width: 320px;
  }

  @media screen and (max-width: 375px){
    width: 280px;
  }

  &_title{
    font-family: 'Lexend-ExtraLight', sans-serif;
    margin-bottom: 32px;
    h2{
      font-size: 2.5rem;
      color: #E65634;
      margin-bottom: 14px;

      @media screen and (max-width: 524px){
        font-size: 1.5rem;
      }
    }

    p{
      font-size: 1.125rem;
      color: #ffffff;
      font-family: 'Lexend-Medium', sans-serif;

      @media screen and (max-width: 524px){
        font-size: 0.9rem;
      }
    }

  }

  &_content{
    width: 100%;
    color: #ffffff;

    &-diagram{
      display: flex;
      align-items: flex-end;
      justify-content: center;
      font-size: 22px;
      margin-bottom: 26px;

      @media screen and (max-width: 1399px){
        flex-direction: column;
        align-items: flex-start;
      }

      .first{
        width: 129px;
        height: 73px;
        background: #881B33;
        display: flex;
        align-items: center;
        justify-content: center;

        @media screen and (max-width: 1399px){
          margin-bottom: 4px;
        }
      }
      .second{
        width: 129px;
        height: 54px;
        background: #BC211F;
        display: flex;
        align-items: center;
        justify-content: center;

        @media screen and (max-width: 1399px){
          margin-bottom: 4px;
        }
      }
      .third{
        width: 773px;
        height: 36px;
        background: #E65634;
        display: flex;
        align-items: center;
        justify-content: center;

        @media screen and (max-width: 1399px){
          width: 100%;
        }
      }
    }

    &-subtext{
      width: 1030px;
      font-size: 18px;
      color: #ffffff;
      text-align: left;
      margin: 0 auto 5px auto;

      @media screen and (max-width: 1399px){
        width: 100%;
      }

      @media screen and (max-width: 768px){
        font-size: 16px;
      }


      .first-p, .second-p, .third-p{
        display: flex;
        align-items: center;
        font-family: 'Lexend-Regular', sans-serif;
        margin-bottom: 20px;

        @media screen and (max-width: 525px){
          font-size: 10px;
        }
      }

      .first-p{
        .color-div{
          width: 78px;
          height: 7px;
          background: #881B33;
          margin-right: 15px;

          @media screen and (max-width: 750px){
            width: 95px;
          }
        }
      }

      .second-p{
        .color-div{
          width: 78px;
          height: 7px;
          background: #BC211F;
          margin-right: 15px;
        }
      }

      .third-p{
        .color-div{
          width: 78px;
          height: 7px;
          background: #E65634;
          margin-right: 15px;
        }
      }
    }
  }

  .diagram{
    margin-top: 75px;


    .diagram-img{
      max-width: 1140px;
      height: 537px;
      position: relative;
      background-image: url("../images/diagramweb.png");
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;

      @media screen and (max-width: 1399px){
        background-image: url("../images/diagram_mobile.png");
      }

      @media screen and (max-width: 620px){
        height: 315px;
      }

      @media screen and (max-width: 524px){
        //height: 200px;
      }

      .diagram-text{
        transform: matrix(0, -1, 1, 0, 0, 0);
        font-size: 18px;
        color: #FF6C4A;
        position: absolute;
        top: 0;
        left: -222px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'Lexend-Medium', sans-serif;

        @media screen and (max-width: 768px){
          font-size: 11px;
          left: -144px;
        }

        @media screen and (max-width: 524px){
          font-size: 8px;
          left: -100px;
        }

      }

      img{
        width: 100%;
        height: 100%;
      }
    }
  }
}

.footer{
  border-top: 1px solid #E65634;
  color: #D8D8D8;
  text-align: left;
  padding: 53px 282px;
  font-family: 'Lexend-Medium', sans-serif;
  display: flex;
  align-items: center;
  justify-content: space-between;

  a{
    color: #D8D8D8;
  }

  button{
    margin: 30px 0;
  }

  .social{
    display: flex;
    align-items: center;

    .icon-discord{
      margin-right: 15px;
    }

    .icon-medium{

    }
  }

  @media screen and (max-width: 1024px){
    padding: 40px 24px;
    //text-align: center;
  }

  @media screen and (max-width: 468px){
    padding: 40px 24px;
    flex-direction: column-reverse;
  }
}
