.filter-table{
  margin-top: 30px;

  .filter{
    display: flex;
    align-items: center;
    height: 30px;

    @media screen and (max-width: 1200px){
      width: 92%;
      margin: 0 auto;
    }

    .name{
      width: 470px;
      display: flex;
      align-items: center;
      cursor: pointer;

      .arrows{
        display: flex;
        flex-direction: column;

        svg{
          margin: 1px 5px;
        }
      }

      @media screen and (max-width: 1200px){
        width: 455px;
      }

      p{
        font-size: 12px;
        color: #8E8E8E;
        margin-left: 52px;
        display: inline-block;

        &:hover{
          color: #FFFFFF;
        }
      }

    }

    .pools{
      width: 200px;
      text-align: center;
      font-size: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #8E8E8E;
      cursor: pointer;

      .arrows{
        display: flex;
        flex-direction: column;

        svg{
          margin: 1px 5px;
        }
      }

      @media screen and (max-width: 1200px){
        width: 195px;
      }

      p{
        display: inline-block;

        &:hover{
          color: #FFFFFF;
        }
      }
    }
    .floor{
      width: 200px;
      text-align: center;
      font-size: 12px;
      justify-content: center;
      display: flex;
      align-items: center;
      color: #8E8E8E;
      cursor: pointer;


      .arrows{
        display: flex;
        flex-direction: column;

        svg{
          margin: 1px 5px;
        }
      }

      @media screen and (max-width: 1200px){
        width: 190px;
      }

      p{
        display: inline-block;

        &:hover{
          color: #FFFFFF;
        }
      }
    }
    .best{
      width: 200px;
      text-align: center;
      justify-content: center;
      font-size: 12px;
      color: #8E8E8E;
      display: flex;
      align-items: center;
      cursor: pointer;


      .arrows{
        display: flex;
        flex-direction: column;

        svg{
          margin: 1px 5px;
        }
      }


      p{
        display: inline-block;

        &:hover{
          color: #FFFFFF;
        }
      }
    }
    .volume{
      width: 200px;
      text-align: center;
      justify-content: center;
      font-size: 12px;
      color: #8E8E8E;
      display: flex;
      align-items: center;
      cursor: pointer;


      .arrows{
        display: flex;
        flex-direction: column;

        svg{
          margin: 1px 5px;
        }
      }

      p{
        display: inline-block;

        &:hover{
          color: #FFFFFF;
        }
      }
    }
  }


  &_item{
    display: flex;
    align-items: center;
    max-width: 100%;
    height: 40px;
    background: #130E1B 0 0;
    padding: 0 11px;
    margin-bottom: 5px;
    cursor: pointer;

    @media screen and (max-width: 1120px){
      max-width: 90%;
      margin: 0 auto 5px auto;
    }

    &:hover{
      background: #221a31 0 0;
    }


    .name{
      width: 450px;
      display: flex;
      align-items: center;
      .avatar{
        width: 26px;
        height: 26px;
        background: #6A0504 0 0;
        border: 1px solid #FFBF46;
        border-radius: 50%;
        cursor: pointer;
        overflow: hidden;

        img{
          width: 100%;
          height: 100%;
        }
      }

      p{
        font-size: 12px;
        color: #FFFFFF;
        margin-left: 12px;
      }

    }

    .pools{
      width: 200px;
      text-align: center;
      font-size: 12px;
      color: #FFFFFF;
    }
    .floor{
      width: 200px;
      text-align: center;
      font-size: 12px;
      color: #FFFFFF;
    }
    .best{
      width: 200px;
      text-align: center;
      font-size: 12px;
      color: #FFFFFF;
    }
    .volume{
      width: 200px;
      text-align: center;
      font-size: 12px;
      color: #FFFFFF;
    }
  }

  .load-more{
    margin: 25px 0 40px;
  }
}
