.poole-section{
  .poole-section-title{
    margin-bottom: 30px;

    h2{
      font-size: 40px;
      color: #FFFFFF;
      font-weight: 200;
      margin: 30px 0 11px ;
      font-family: Lexend-ExtraLight, sans-serif;
    }
    p{
      color: #8E8E8E;
      margin-bottom: 3px;
      font-family: Lexend-Regular, sans-serif;
    }

    &_create{
      margin-top: 30px;
      margin-bottom: 29px;

      a{
        margin-top: 30px;
        cursor: default;
      }
    }


  }

  .poole-section-content{
    display: flex;
    justify-content: center;
    height: 678px;

    .poll-list{
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin-left: 32px;
      width: 1130px;

      @media screen and (max-width: 1440px){
        width: 855px;
      }

      @media screen and (max-width: 970px){
        margin: 0;
        justify-content: center;
      }

      a{


        //@media screen and (max-width: 970px){
        //
        //}
      }

      .pool-item{
        width: 267px;
        height: 246px;
        background: #000000 0 0;
        border-radius: 5px;
        margin: 5px 5px;


        @media screen and (max-width: 970px){
          width: 100%;
        }
      }
    }
  }





}
